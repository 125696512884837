<template>
  <footer class="py-3 footer">
    <div class="container-fluid"></div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
