//const baseURLApi = 'http://localhost:5071';
const baseURLApi = 'https://api.antique-watches.de';

export default {
  baseURLApi,
  isBackend: process.env.VUE_APP_BACKEND,
  api: {
    url: `${baseURLApi}/api`,
  }
};
