import axios from "axios";
import config from "../config";

export default (props = { headers: {} }) => {
  let headers = {
    "cache-control": "no-cache",
    ...props.headers,
  };

  // const accessToken = TokenService.getToken();
  // if (accessToken && accessToken !== "") {
  //   headers.Authorization = accessToken;
  // }

  const instance = axios.create({
    baseURL: config.api.url,
    headers: headers,
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // if (error.response.status === 401 || error.response.status === 403) {
      //   // TokenService.clearToken();
      //   if (router.history.current.path !== "/login") {
      //     window.VueSingleton &&
      //       window.VueSingleton.$notify({
      //         timeout: 2500,
      //         message: "Forbiden",
      //         icon: "add_alert",
      //         horizontalAlign: "right",
      //         verticalAlign: "top",
      //         type: "danger",
      //       });
      //     //router.replace({ path: "/login", query: { returnUrl: router.history.current.path } });
      //   }

      //   return Promise.resolve({ data: { data: {} } });
      // }

      if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
      }

      return Promise.reject(error.message);
    }
  );

  return instance;
};
