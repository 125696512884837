<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="form-group">
        <input
          v-model="filter.keywords"
          type="text"
          placeholder="Keywords..."
          class="form-control"
          @input="debounceApplyFilters"
        />
      </div>
      <v-server-table id="caliber-table" ref="table" :columns="tableColumns" :options="tableOptions">
        <template #name="{ row }">
          <div class="name-cell container">
            <div class="row row-cols-2">
              <div class="col col-sm-2">
                <img class="avatar" :src="getDefaultImage(row.id)" />
              </div>
              <div class="col col-sm-10">
                <a href="javascript:void(0);" @click="showDetailDialog(row)">{{ row.name }}</a> <br />
                {{ row.manufacturer }}
              </div>
            </div>
          </div>
        </template>
      </v-server-table>
    </div>

    <!-- Modal -->
    <div
      id="details-modal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style="z-index: 9999"
    >
      <div class="modal-dialog" style="max-width: 98vw !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">{{ caliber.name }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="name-cell container" style="max-width: calc(95vw - 46px);margin:0">
              <div class="row row-cols-3">
                <div class="col col-xl-2">
                  <img
                    v-for="image in caliberImages"
                    :key="image.id"
                    :src="getImage(image.id)"
                    style="display: block; width: 200px; margin: 0 auto 24px auto"
                  />
                </div>
                <div class="col col-xl-5">
                  <h3>{{ caliber.name }}</h3>

                  <h4>Basic Information</h4>
                  <table class="table table-striped table-bordered table-hover detail-view fixed-name-column">
                    <tbody>
                      <tr>
                        <th style="width: 200px">Manufacturer</th>
                        <td>{{ caliber.manufacturer }}</td>
                      </tr>
                      <tr>
                        <th>Launch Year</th>
                        <td>{{ caliber.launchYear }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h4>Technical Details</h4>
                  <table class="table table-striped table-bordered table-hover detail-view fixed-name-column">
                    <tbody>
                      <tr>
                        <th style="width: 200px">Dimensions</th>
                        <td>{{ caliber.dimensions }}</td>
                      </tr>
                      <tr>
                        <th>Height</th>
                        <td>{{ caliber.height }}</td>
                      </tr>
                      <tr>
                        <th>Further Measures</th>
                        <td>
                          <b>Mounting Diameter (Dm):</b> {{ caliber.furtherMeasureMountingDiameter }}<br />
                          <b>Stem Height:</b> {{ caliber.furtherMeasureStemHeight }}<br />
                        </td>
                      </tr>
                      <tr>
                        <th>Power Reserve</th>
                        <td>{{ caliber.powerReserve }}</td>
                      </tr>
                      <tr>
                        <th>Frequency</th>
                        <td>{{ caliber.frequency }}</td>
                      </tr>
                      <tr>
                        <th>Jewels</th>
                        <td>{{ caliber.jewels }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col col-xl-5">
                  <h3>&nbsp;</h3>
                  <h4>Complications and Specifications</h4>
                  <table class="table table-striped table-bordered table-hover detail-view fixed-name-column">
                    <tbody>
                      <tr>
                        <th style="width: 200px">Shape</th>
                        <td>{{ caliber.shape }}</td>
                      </tr>
                      <tr>
                        <th>Complications</th>
                        <td>
                          <ul>
                            <li v-for="c in showComplications(caliber.complications) " :key="c">
                              {{ c }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th>Regulator Type</th>
                        <td>{{ caliber.regulatorType }}</td>
                      </tr>
                      <tr>
                        <th>Movement Type</th>
                        <td>{{ caliber.movementType }}</td>
                      </tr>
                      <tr>
                        <th>Rotor Type</th>
                        <td>{{ caliber.rotorType }}</td>
                      </tr>
                      <tr>
                        <th>Escapement Type</th>
                        <td>{{ caliber.escapementType }}</td>
                      </tr>
                      <tr>
                        <th>Control Type</th>
                        <td>{{ caliber.controlType }}</td>
                      </tr>
                      <tr>
                        <th>Winding Type</th>
                        <td>{{ caliber.windingType }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h4>Additional Information</h4>
                  <table class="table table-striped table-bordered table-hover detail-view fixed-name-column">
                    <tbody>
                      <tr>
                        <th style="width: 200px">Hand Sizes</th>
                        <td>{{ caliber.handSizes }}</td>
                      </tr>
                      <tr>
                        <th>Remarks</th>
                        <td style="white-space:wrap">
                          <div style="text-wrap:wrap" v-html="getNotes(caliber.notes)"></div>
                        </td>
                      </tr>
                      <tr>
                        <th>Balance Staff</th>
                        <td>{{ caliber.balanceStaff }}</td>
                      </tr>
                      <tr>
                        <th>Stem</th>
                        <td>{{ caliber.stem }}</td>
                      </tr>
                      <tr>
                        <th>MainSpring / Battery</th>
                        <td>{{ caliber.mainSpringBattery }}</td>
                      </tr>
                      <tr>
                        <th>Signature</th>
                        <td>{{ caliber.signature }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { Modal } from "bootstrap";

import axiosFactory from "../../services/axios.factory";
import config from "../../config";

export default {
  name: "DashboardDefault",
  components: {},
  data() {
    return {
      detailsModal: null,
      currentPage: 1,
      tableData: [],
      totalItems: 0,
      caliber: {},
      caliberImages: [],
      filter: {
        keywords: null,
      },
      tableColumns: ["name", "movementType", "dimensions", "launchYear"],
      tableOptions: {
        headings: {
          status: () => "Name",
          movementType: () => "Movement Type",
          dimensions: () => "Dimensions",
          launchYear: () => "Launch Year",
        },
        perPage: 25,
        perPageValues: [25],
        filterable: false,
        sortable: [],
        params: {},
        resizableColumns: false,
        requestFunction: this.requestFunction,
        responseAdapter: this.responseFunction,
        rowClassCallback: this.rowClassCallback,
        stickyHeader: true,
        columnsClasses: {},
        pagination: {
          show: true,
        },
      },
    };
  },
  mounted() {
    this.detailsModal = new Modal(document.getElementById("details-modal"));
  },
  methods: {
    debounceApplyFilters: debounce(function () {
      this.applyFilters({});
    }, 300),
    applyFilters(filters) {
      this.$refs.table.options.params = {
        ...this.$refs.table.options.params,
        ...this.filter,
        ...filters,
        pageIndex: 0,
      };
      this.currentPage = 1;
      this.$refs.table.setPage(this.currentPage);
    },
    requestFunction(data) {
      const { page, limit, orderBy, ascending, ...filters } = data;
      this.currentPage = page;
      let params = {
        pageIndex: this.currentPage,
        pageSize: limit,
        orderBy,
        ascending,
        ...filters,
      };

      this.isLoading = true;
      return axiosFactory()
        .get(`/calibers`, { params })
        .catch(function () {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    responseFunction({ data }) {
      this.tableData = data.data.map((i) => ({ ...i, selected: false }));
      this.totalItems = data.total;

      return {
        data: this.tableData,
        count: data.total,
      };
    },
    rowClassCallback() {
      return "";
    },
    getDefaultImage(caliberId) {
      return `${config.api.url}/caliber_images/default/${caliberId}`;
    },
    getImage(caliberImageId) {
      return `${config.api.url}/caliber_images/${caliberImageId}`;
    },
    showDetailDialog(row) {
      axiosFactory()
        .get(`/calibers/${row.id}/images`)
        .then((res) => {
          this.caliberImages = res?.data?.data ?? [];
        })
        .catch(function () {})
        .finally(() => {
          this.isLoading = false;
        });
      
      axiosFactory()
        .get(`/calibers/${row.id}`)
        .then((res) => {
          this.caliber = res?.data?.data ?? row;
          this.detailsModal.show();
        })
        .catch(function () {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    showComplications(complications) {
      if (!complications) return [];

      return complications.split(',');
    },
    getNotes(notes) {
      if (!notes) return '';
      const idx = notes.indexOf('Other data:');
      if (idx >= 0) {
        let res = notes.substring(0, idx);
        res = res.replaceAll('<b>', '');
        res = res.replaceAll('</b>', '');
        res = res.replaceAll('Remarks:', '');
        return res;
      }

      return notes;
    }
  },
};
</script>
